
                @import '@style/mixins';
                @import '@skillbox/front-box/dist/Functional/UiVariables/style';
              
@import '../../universal-notice/v1/style';

.header {
  width: 100%;
  background-color: var(--bg-main-color);

  @include media(md) {
    padding: 0;
  }

  &--sticky {
    @include z-index(sticky, header);

    top: 0;
    left: 0;
  }
}

.universal-notice-wrapper:has(.universal-notice:not(.universal-notice--new-design)) ~ .header--sticky {
  top: 56px;

  @include media(lg) {
    top: 72px;
  }
}

.universal-notice-wrapper:has(.universal-notice-sticky) ~ .header--sticky {
  top: 40px;

  @include media(lg) {
    top: 48px;
  }
}

.header__wrapper {
  display: grid;
  grid-gap: 2px 10px;
  grid-template-columns: 84px auto;
  align-items: center;
  justify-content: space-between;
  padding-top: 13px;
  padding-bottom: 13px;

  @include media(md) {
    grid-template-columns: 108px auto;
    padding-top: 36px;
    padding-bottom: 36px;
  }

  @include media(lg) {
    padding-top: 34px;
    padding-bottom: 34px;
  }

  @include media(xl) {
    padding-top: 34px;
    padding-bottom: 34px;
  }

  &--phone {
    padding-top: 15px;
    padding-bottom: 15px;

    @include media(md) {
      padding-top: 26px;
      padding-bottom: 26px;
    }

    @include media(lg) {
      padding-top: 24px;
      padding-bottom: 24px;
    }

    @include media(xl) {
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }

  &--nav {
    padding-top: 21px;
    padding-bottom: 21px;

    @include media(md) {
      grid-template-columns: 108px auto;
      padding-top: 32px;
      padding-bottom: 32px;
    }

    @include media(lg) {
      padding-top: 32px;
      padding-bottom: 32px;
    }

    @include media(xl) {
      padding-top: 28px;
      padding-bottom: 28px;
    }
  }
}

.header__logo {
  grid-row: 1/3;

  @include media(md) {
    width: 108px;
    height: 24px;
  }

  img {
    display: block;
    max-height: 100%;

    @include media(md) {
      width: 100%;
    }
  }
}

.header__wrapper--nav .header__logo {
  grid-row: 1/2;
}

.header__text {
  grid-row: 1/3;
  text-align: right;

  @include media(sm-max) {
    max-width: 185px;
  }
}

.header__wrapper--phone .header__text {
  grid-row: 1/2;
}

.header__phone {
  grid-row: 2/3;
  grid-column: 2/3;
  justify-self: flex-end;
  transition: color $duration-hover-button;

  @include hover {
    color: var(--text-addition-color);
  }

  &:focus-visible {
    color: var(--text-addition-color);
  }
}

.is-safari .header__phone {
  &:focus {
    color: var(--text-addition-color);
  }
}

.header__marketplace {
  display: flex;
  align-items: center;
  padding-top: 13px;
  padding-bottom: 13px;

  @include media(md) {
    padding-top: 36px;
    padding-bottom: 36px;
  }

  @include media(lg) {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  &--logo {
    @include media(md) {
      padding-top: 32px;
      padding-bottom: 32px;
    }

    @include media(lg) {
      padding-top: 32px;
      padding-bottom: 32px;
    }
  }
}

.header__university-logo {
  width: auto;
  height: 32px;
  margin-right: 20px;
}

.header__marketplace-title {
  @include media(sm-max) {
    max-width: 280px;
  }
}

// Для UiModal
.header-mobile-nav {
  position: absolute;
  top: 0;
  right: 0;
  width: calc(100% - 20px);
  height: 100%;
  padding: 40px 20px 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background: var(--bg-main-color);
  transform: translateX(100%);
  transition: transform 300ms ease-in-out;

  @include media(md) {
    width: calc(100% - 30px);
    padding: 40px;
  }

  @include media(lg) {
    padding: 60px;
  }

  &--active {
    transform: translateX(0);
  }
}

.header__nav {
  display: none;

  @include media(xl) {
    display: block;
  }

  &--mobile {
    display: grid;
    grid-gap: 20px;
    grid-template-rows: min-content minmax(50px, min-content) 1fr;
    align-items: flex-end;
    height: 100%;

    @include media(lg) {
      grid-gap: 40px;
    }
  }
}

.header__nav-list {
  @include ulres;
  max-height: 100%;

  overflow-y: auto;

  @include media(xl) {
    display: flex;
  }
}

.header__nav-item {
  &:not(:last-child) {
    @include media(xl) {
      margin-right: 12px;
    }
  }
}

.header__nav-link {
  display: block;
  padding: 16px 0;

  @include media(xl) {
    padding: 10px 12px;
    border-radius: 100px;
    transition: background-color $duration-hover-button;
  }

  &:hover {
    @include media(xl) {
      background-color: var(--bg-secondary-color);
    }
  }
}

.header__nav-btn {
  @include inpres;

  display: flex;
  align-items: center;
  height: 32px;
  padding: 4px 12px;
  border-radius: 100px;
  background-color: var(--bg-secondary-color);
  cursor: pointer;

  @include media(xl) {
    display: none;
  }
}

.header__nav-icon {
  margin-right: 8px;
}

.header__nav-order {
  justify-self: center;

  @include media(md-max) {
    margin-top: 20px;
  }
}

.header__nav-title {
  margin-bottom: 0;
}

@keyframes showHeader {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes hideHeader {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}
